import React, { useState } from "react";

const VerbalConsentPolicy = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    consent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log("Form submitted:", formData);
    // Optionally, send formData to your backend server
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">
        Our Opt-In Process
      </h1>
      <p className="text-lg text-gray-600 mb-8">
        At <strong className="text-gray-800">Operaitor</strong>, we value your
        privacy and comply with all messaging regulations. Here's how you can
        opt-in to receive text messages from us.
      </p>

      <section>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Consent Details
        </h2>
        <p className="text-gray-600 bg-gray-50 p-4 rounded-lg border border-gray-200">
          By checking the consent box and submitting the form, you agree to
          receive recurring automated text messages from Operaitor for
          appointment confirmations and notifications. Message frequency varies.
          Message and data rates may apply. You can reply STOP at any time to
          opt-out.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Managing Your Consent
        </h2>
        <p className="text-gray-600 bg-blue-50 p-4 rounded-lg border border-blue-200">
          To opt-out, simply reply STOP to any of our messages.
        </p>
      </section>

      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Opt-In Form
          </h2>
          <form
            onSubmit={handleSubmit}
            className="space-y-6 bg-gray-50 p-6 rounded-lg border border-gray-200"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
                pattern="[0-9]{10}" // Adjust the pattern as per your requirements
                title="Enter a valid phone number"
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div className="flex items-start space-x-3">
              <input
                type="checkbox"
                id="consent"
                name="consent"
                checked={formData.consent}
                onChange={handleChange}
                className="mt-1 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                required
              />
              <label htmlFor="consent" className="text-sm text-gray-600">
                By checking this box, I agree to receive recurring automated
                text messages from Operaitor for appointment confirmations and
                notifications at the phone number provided. Message frequency
                varies. Message and data rates may apply. I can opt-out at any
                time by replying STOP to any message. I also agree to the{" "}
                <a href="/terms" className="text-blue-600 underline">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a href="/privacy" className="text-blue-600 underline">
                  Privacy Policy
                </a>
                .
              </label>
            </div>

            <button
              type="submit"
              className="w-full md:w-auto px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors duration-200"
            >
              Submit
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default VerbalConsentPolicy;
