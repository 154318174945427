import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Box } from "@mui/material";
import Navigation from "./Navigation";
import Home from "./Home";
import About from "./About";
import Login from "./Login";
import Signup from "./Signup";
import Pricing from "./Pricing";
import Apply from "./pages/Apply";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppointmentPage from "./pages/AppointmentPage";
import PatientPage from "./pages/PatientPage";
import SideDrawer from "./components/SideDrawer";
import { createClient } from "@supabase/supabase-js";
import MessagePage from "./pages/MessagePage";
import SettingsPage from "./pages/SettingsPage";
import NewPatientForm from "./pages/NewPatientForm";
import FileUploadPage from "./pages/FileUploadPage";
import Privacypolicy from "./pages/Privacypolicy";
import Admin from "./pages/admin";
import DentalScheduler from "./pages/DentalScheduler";
import Dashboard from "./pages/Dashboard";
import VerbalConsentPolicy from "./pages/VerbalConsentPolicy";
import TermsAndConditions from "./pages/Termsandconditions";
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function App() {
  const [session, setSession] = useState(null);
  const location = useLocation();

  // Define refs and handleScrollTo function
  const privacyRef = useRef(null);
  const testimonialRef = useRef(null);
  const pricingRef = useRef(null);
  const faqRef = useRef(null);
  const featureRef = useRef(null);
  const howitworksRef = useRef(null);

  const handleScrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <ToastContainer />

      {session ? (
        <SideDrawer />
      ) : (
        <Navigation
          handleScrollTo={isHomePage ? handleScrollTo : null}
          refs={
            isHomePage
              ? {
                  privacyRef,
                  testimonialRef,
                  pricingRef,
                  faqRef,
                  featureRef,
                  howitworksRef,
                }
              : {}
          }
        />
      )}
      {session ? (
        <Box sx={{ display: "flex" }}>
          <Box component="main" sx={{ flexGrow: 1, pt: 8, marginLeft: "58px" }}>
            <Routes>
              <Route path="/" element={<Navigate to="/scheduler" replace />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/apply" element={<Apply />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/appointments" element={<AppointmentPage />} />
              <Route path="/patients" element={<PatientPage />} />
              <Route path="/messages" element={<MessagePage />} />
              <Route path="/setting" element={<SettingsPage />} />
              <Route path="/fileuploadpage" element={<FileUploadPage />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/scheduler" element={<DentalScheduler />} />
              <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
          </Box>
        </Box>
      ) : (
        <Routes>
          {/* <Route path="/" exact element={<Home />} /> */}
          <Route
            path="/"
            exact
            element={
              <Home
                refs={{
                  privacyRef,
                  testimonialRef,
                  pricingRef,
                  faqRef,
                  featureRef,
                  howitworksRef,
                }}
              />
            }
          />
          <Route
            path="/verbal-consent-policy"
            element={<VerbalConsentPolicy />}
          />
          <Route path="/privacypolicy" element={<privacypolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/newPatientForm" element={<NewPatientForm />} />
        </Routes>
      )}
    </>
  );
}

export default App;
