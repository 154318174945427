import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
      <p className="text-gray-700 mb-4">Last updated: December 1, 2024</p>
      <p className="text-gray-700 mb-4">
        Please read these terms and conditions carefully before using Our
        Service.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">
        Interpretation and Definitions
      </h2>

      <h3 className="text-xl font-semibold mb-2">Interpretation</h3>
      <p className="text-gray-700 mb-4">
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>

      <h3 className="text-xl font-semibold mb-2">Definitions</h3>
      <p className="text-gray-700 mb-4">
        For the purposes of these Terms and Conditions:
      </p>
      <ul className="list-disc list-inside mb-4 text-gray-700">
        <li>
          <strong>Affiliate</strong> means an entity that controls, is
          controlled by, or is under common control with a party, where
          "control" means ownership of 50% or more of the shares, equity
          interest or other securities entitled to vote for election of
          directors or other managing authority.
        </li>
        <li>
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </li>
        <li>
          <strong>Country</strong> refers to: New York, United States.
        </li>
        <li>
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to Operaitor corp., 3 Grace
          Ave, Great Neck, NY 11021.
        </li>
        <li>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cellphone or a digital tablet.
        </li>
        <li>
          <strong>Feedback</strong> means feedback, innovations or suggestions
          sent by You regarding the attributes, performance or features of our
          Service.
        </li>
        <li>
          <strong>Free Trial</strong> refers to a limited period of time that
          may be free when purchasing a Subscription.
        </li>
        <li>
          <strong>Promotions</strong> refer to contests, sweepstakes or other
          promotions offered through the Service.
        </li>
        <li>
          <strong>Service</strong> refers to the Website.
        </li>
        <li>
          <strong>Subscriptions</strong> refer to the services or access to the
          Service offered on a subscription basis by the Company to You.
        </li>
        <li>
          <strong>Terms and Conditions</strong> (also referred as "Terms") mean
          these Terms and Conditions that form the entire agreement between You
          and the Company regarding the use of the Service.
        </li>
        <li>
          <strong>Third-party Social Media Service</strong> means any services
          or content (including data, information, products or services)
          provided by a third-party that may be displayed, included or made
          available by the Service.
        </li>
        <li>
          <strong>Website</strong> refers to Operaitor corp., accessible from{" "}
          <a
            href="https://www.operaitor.online/"
            className="text-blue-600 hover:underline"
          >
            https://www.operaitor.online/
          </a>
        </li>
        <li>
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-4">Acknowledgment</h2>
      <p className="text-gray-700 mb-4">
        These are the Terms and Conditions governing the use of this Service and
        the agreement that operates between You and the Company. These Terms and
        Conditions set out the rights and obligations of all users regarding the
        use of the Service.
      </p>
      <p className="text-gray-700 mb-4">
        Your access to and use of the Service is conditioned on Your acceptance
        of and compliance with these Terms and Conditions. These Terms and
        Conditions apply to all visitors, users and others who access or use the
        Service.
      </p>
      <p className="text-gray-700 mb-4">
        By accessing or using the Service You agree to be bound by these Terms
        and Conditions. If You disagree with any part of these Terms and
        Conditions then You may not access the Service.
      </p>
      <p className="text-gray-700 mb-4">
        You represent that you are over the age of 18. The Company does not
        permit those under 18 to use the Service.
      </p>
      <p className="text-gray-700 mb-4">
        Your access to and use of the Service is also conditioned on Your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of Your personal information when You use the
        Application or the Website and tells You about Your privacy rights and
        how the law protects You. Please read Our Privacy Policy carefully
        before using Our Service.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">Subscriptions</h2>
      <h3 className="text-xl font-semibold mb-2">Subscription period</h3>

      <p className="text-gray-700 mb-4">
        The Service or some parts of the Service are available only with a paid
        Subscription. You will be billed in advance on a recurring and periodic
        basis (such as daily, weekly, monthly or annually), depending on the
        type of Subscription plan you select when purchasing the Subscription.
        At the end of each period, Your Subscription will automatically renew
        under the exact same conditions unless You cancel it or the Company
        cancels it.
      </p>

      <h3 className="text-xl font-semibold mb-2">Subscription cancellations</h3>

      <p className="text-gray-700 mb-4">
        You may cancel Your Subscription renewal either through Your Account
        settings page or by contacting the Company. You will not receive a
        refund for the fees You already paid for Your current Subscription
        period and You will be able to access the Service until the end of Your
        current Subscription period.
      </p>
      <h3 className="text-xl font-semibold mb-2">Billing</h3>
      <p className="text-gray-700 mb-4">
        You shall provide the Company with accurate and complete billing
        information including full name, address, state, zip code, telephone
        number, and a valid payment method information. Should automatic billing
        fail to occur for any reason, the Company will issue an electronic
        invoice indicating that you must proceed manually, within a certain
        deadline date, with the full payment corresponding to the billing period
        as indicated on the invoice.
      </p>
      <h3 className="text-xl font-semibold mb-2">Refunds</h3>
      <p className="text-gray-700 mb-4">
        Except when required by law, paid Subscription fees are non-refundable.
        Certain refund requests for Subscriptions may be considered by the
        Company on a case-by-case basis and granted at the sole discretion of
        the Company.
      </p>
      <h3 className="text-xl font-semibold mb-2">Free Trial</h3>
      <p className="text-gray-700 mb-4">
        The Company may, at its sole discretion, offer a Subscription with a
        Free Trial for a limited period of time. You may be required to enter
        Your billing information in order to sign up for the Free Trial. If You
        do enter Your billing information when signing up for a Free Trial, You
        will not be charged by the Company until the Free Trial has expired. On
        the last day of the Free Trial period, unless You canceled Your
        Subscription, You will be automatically charged the applicable
        Subscription fees for the type of Subscription You have selected. At any
        time and without notice, the Company reserves the right to (i) modify
        the terms and conditions of the Free Trial offer, or (ii) cancel such
        Free Trial offer.
      </p>
      <h3 className="text-xl font-semibold mb-2">Promotions</h3>
      <p className="text-gray-700 mb-4">
        Any Promotions made available through the Service may be governed by
        rules that are separate from these Terms. If You participate in any
        Promotions, please review the applicable rules as well as our Privacy
        policy. If the rules for a Promotion conflict with these Terms, the
        Promotion rules will apply.
      </p>
      <h2 className="text-2xl font-bold mt-6 mb-4">The Operaitor Services.</h2>

      <h3 className="text-xl font-semibold mb-2">Eligibility</h3>
      <p className="text-gray-700 mb-4">
        You may use the Services only in accordance with these Terms and in
        compliance with all applicable local, state, national and international
        laws, rules and regulations. You may use the Services only if you have
        the legal power and capacity to form a contract with Operaitor. Any use
        or access to the Services by anyone under eighteen (18) years of age is
        strictly prohibited and in violation of these Terms. The Services are
        not available to any users previously removed or prohibited from using
        the Services by Operaitor.
      </p>
      <h3 className="text-xl font-semibold mb-2">Call Recording</h3>
      <p className="text-gray-700 mb-4">
        Subject to your agreement to be bound by these Terms and subject to
        these Terms, you may enable or disable our Service which permits you to
        record telephone conversations (each such recording, a “Call Recording”)
        by contacting operaitorai@gmail.com. For each Call Recording, you will
        obtain all consents and/or provide all disclosures required under all
        applicable Calling Laws.
      </p>
      <h3 className="text-xl font-semibold mb-2">SMS</h3>
      <p className="text-gray-700 mb-4">
        Subject to your agreement to be bound by these Terms and subject to
        these Terms, users may receive text messages. For our SMS feature, users
        are required to give verbal consent to receive text messages. Consent
        can be given during a phone call or during an in-person visit and shall
        be stored. Users are able to opt-out of this feature by texting STOP or
        rejecting the opt-in question. For each user, you will obtain all
        consents and/or provide all disclosures required under all applicable
        SMS laws.
      </p>
      <p className="text-gray-700 mb-4">
        Operaitor Agent: "Would you like to receive confirmation text messages
        when you update appointments? Message and data rates may apply. You may
        always opt out by replying STOP. Please reply with “yes” or “no” to
        indicate if you would like this service." Operaitor Customer: "Yes"
        Operaitor Agent: “Great! We will send you a text message to confirm your
        enrollment here shortly.”
      </p>
      <h3 className="text-xl font-semibold mb-2">User Accounts</h3>
      <p className="text-gray-700 mb-4">
        When You create an account with Us, You must provide Us with information
        that is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of Your account on Our Service. You are responsible for
        safeguarding the password that You use to access the Service and for any
        activities or actions under Your password, whether Your password is with
        Our Service or a Third-Party Social Media Service. You agree not to
        disclose Your password to any third party. You must notify Us
        immediately upon becoming aware of any breach of security or
        unauthorized use of Your account. You may not use as a username the name
        of another person or entity or that is not lawfully available for use, a
        name or trademark that is subject to any rights of another person or
        entity other than You without appropriate authorization, or a name that
        is otherwise offensive, vulgar or obscene.
      </p>
      <h3 className="text-xl font-semibold mb-2">Intellectual Property</h3>
      <p className="text-gray-700 mb-4">
        The Service and its original content (excluding Content provided by You
        or other users), features and functionality are and will remain the
        exclusive property of the Company and its licensors. The Service is
        protected by copyright, trademark, and other laws of both the Country
        and foreign countries. Our trademarks and trade dress may not be used in
        connection with any product or service without the prior written consent
        of the Company.
      </p>
      <h3 className="text-xl font-semibold mb-2">Your Feedback to Us</h3>
      <p className="text-gray-700 mb-4">
        You assign all rights, title, and interest in any Feedback You provide
        the Company. If for any reason such assignment is ineffective, You agree
        to grant the Company a non-exclusive, perpetual, irrevocable,
        royalty-free, worldwide right and license to use, reproduce, disclose,
        sub-license, distribute, modify and exploit such Feedback without
        restriction.
      </p>
      <h3 className="text-xl font-semibold mb-2">Links to Other Websites</h3>
      <p className="text-gray-700 mb-4">
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by the Company. The Company has no control
        over, and assumes no responsibility for, the content, privacy policies,
        or practices of any third-party websites or services. You further
        acknowledge and agree that the Company shall not be responsible or
        liable, directly or indirectly, for any damage or loss caused or alleged
        to be caused by or in connection with the use of or reliance on any such
        content, goods or services available on or through any such web sites or
        services. We strongly advise You to read the terms and conditions and
        privacy policies of any third-party web sites or services that You
        visit.
      </p>
      <h3 className="text-xl font-semibold mb-2">Termination</h3>
      <p className="text-gray-700 mb-4">
        We may terminate or suspend Your Account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if You breach these Terms and Conditions. Upon termination,
        Your right to use the Service will cease immediately. If You wish to
        terminate Your Account, You may simply discontinue using the Service.
      </p>
      <h3 className="text-xl font-semibold mb-2">Limitation of Liability</h3>
      <p className="text-gray-700 mb-4">
        Notwithstanding any damages that You might incur, the entire liability
        of the Company and any of its suppliers under any provision of this
        Terms and Your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by You in the past three months
        through the Service or 100 USD if You haven't purchased anything through
        the Service. To the maximum extent permitted by applicable law, in no
        event shall the Company or its suppliers be liable for any special,
        incidental, indirect, or consequential damages whatsoever (including,
        but not limited to, damages for loss of profits, loss of data or other
        information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability
        to use the Service, third-party software and/or third-party hardware
        used with the Service, or otherwise in connection with any provision of
        this Terms), even if the Company or any supplier has been advised of the
        possibility of such damages and even if the remedy fails of its
        essential purpose. Some states do not allow the exclusion of implied
        warranties or limitation of liability for incidental or consequential
        damages, which means that some of the above limitations may not apply.
        In these states, each party's liability will be limited to the greatest
        extent permitted by law.
      </p>
      <h3 className="text-xl font-semibold mb-2">
        "AS IS" and "AS AVAILABLE" Disclaimer
      </h3>
      <p className="text-gray-700 mb-4">
        The Service is provided to You "AS IS" and "AS AVAILABLE" and with all
        faults and defects without warranty of any kind. To the maximum extent
        permitted under applicable law, the Company, on its own behalf and on
        behalf of its Affiliates and its and their respective licensors and
        service providers, expressly disclaims all warranties, whether express,
        implied, statutory or otherwise, with respect to the Service, including
        all implied warranties of merchantability, fitness for a particular
        purpose, title and non-infringement, and warranties that may arise out
        of course of dealing, course of performance, usage or trade practice.
        Without limitation to the foregoing, the Company provides no warranty or
        undertaking, and makes no representation of any kind that the Service
        will meet Your requirements, achieve any intended results, be compatible
        or work with any other software, applications, systems or services,
        operate without interruption, meet any performance or reliability
        standards or be error free or that any errors or defects can or will be
        corrected. Without limiting the foregoing, neither the Company nor any
        of the Company's provider makes any representation or warranty of any
        kind, express or implied: (i) as to the operation or availability of the
        Service, or the information, content, and materials or products included
        thereon; (ii) that the Service will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the Service; or (iv) that the Service, its
        servers, the content, or e-mails sent from or on behalf of the Company
        are free of viruses, scripts, trojan horses, worms, malware, timebombs
        or other harmful components. Some jurisdictions do not allow the
        exclusion of certain types of warranties or limitations on applicable
        statutory rights of a consumer, so some or all of the above exclusions
        and limitations may not apply to You. But in such a case the exclusions
        and limitations set forth in this section shall be applied to the
        greatest extent enforceable under applicable law.
      </p>
      <h3 className="text-xl font-semibold mb-2">Governing Law</h3>
      <p className="text-gray-700 mb-4">
        The laws of the Country, excluding its conflicts of law rules, shall
        govern these Terms and Your use of the Service. Your use of the
        Application may also be subject to other local, state, national, or
        international laws.
      </p>
      <h3 className="text-xl font-semibold mb-2">Disputes Resolution</h3>
      <p className="text-gray-700 mb-4">
        If You have any concern or dispute about the Service, You agree to first
        try to resolve the dispute informally by contacting the Company.
      </p>
      <h3 className="text-xl font-semibold mb-2">
        For European Union (EU) Users
      </h3>
      <p className="text-gray-700 mb-4">
        If You are a European Union consumer, you will benefit from any
        mandatory provisions of the law of the country in which you are resident
        in.
      </p>
      <h3 className="text-xl font-semibold mb-2">
        United States Federal Government End-Use Provisions
      </h3>
      <p className="text-gray-700 mb-4">
        If You are a U.S. federal government end user, our Service is a
        "Commercial Item" as that term is defined at 48 C.F.R. §2.101.
      </p>
      <h2 className="text-2xl font-bold mt-6 mb-4">Severability and Waiver</h2>

      <h3 className="text-xl font-semibold mb-2">Severability</h3>
      <p className="text-gray-700 mb-4">
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>
      <h3 className="text-xl font-semibold mb-2">Waiver</h3>
      <p className="text-gray-700 mb-4">
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under these Terms shall not affect a
        party's ability to exercise such right or require such performance at
        any time after that nor shall the waiver of a breach constitute a waiver
        of any subsequent breach.
      </p>
      <h3 className="text-xl font-semibold mb-2">Translation Interpretation</h3>
      <p className="text-gray-700 mb-4">
        These Terms and Conditions may have been translated if we had made them
        available to you through our Service. You agree that the original
        English text shall prevail in the case of a dispute.
      </p>
      <h3 className="text-xl font-semibold mb-2">
        Changes to These Terms and Conditions
      </h3>
      <p className="text-gray-700 mb-4">
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time. If a revision is material We will make reasonable
        efforts to provide at least 30 days' notice prior to any new terms
        taking effect. What constitutes a material change will be determined at
        Our sole discretion. By continuing to access or use Our Service after
        those revisions become effective, You agree to be bound by the revised
        terms. If You do not agree to the new terms, in whole or in part, please
        stop using the website and the Service.
      </p>
      <h3 className="text-xl font-semibold mb-2">Contact Us</h3>
      <p className="text-gray-700 mb-4">
        If you have any questions about these Terms and Conditions, You can
        contact us: operaitorai@gmail.com
      </p>
    </div>
  );
};

export default TermsAndConditions;
